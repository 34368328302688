import $ from 'jquery';
import 'what-input';
import './lib/slick';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below


$(document).foundation();

$(document).ready(function () {
  $('.carousel_slick').slick({
    focusOnSelect: true,
    dots: true,
    autoplay: true,

    pauseOnHover: false,
    arrows: true,
    infinite: true,

    autoplaySpeed: 2400,
    speed: 500,
    slidesToShow: 1,
    adaptiveHeight: false

  });
});
// Slick 再生停止ボタン
$('.btn_stop').on('click', function () {
  $('.carousel_slick').slick('slickPause');
});
$('.btn_play').on('click', function () {
  $('.carousel_slick').slick('slickPlay');
});

// 画像ダウンロード制限
// $(function () {
//   $(".main_area img").wrap('<div class="img-hide"></div>');
// });
$(function () {
  $(".main_area img").on("contextmenu", function(){
    return false;
  });
});